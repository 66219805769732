var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "intro" }, [
    _c("div", { staticClass: "intro-head", on: { click: _vm.onToggle } }, [
      _c("span", [_vm._v("需求简介")]),
      _vm.showmore
        ? _c("img", {
            class: [_vm.isExpand ? "expand" : ""],
            attrs: {
              src: require("@/assets/img/work/icon_arrow_down.svg"),
              alt: ""
            }
          })
        : _vm._e()
    ]),
    _c("div", {
      class: ["intro-content", !_vm.isExpand && _vm.showmore && "line2"],
      domProps: { textContent: _vm._s(_vm.entity.content) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }