const components = {}

const loadComp = require.context(
  '.', false, /\.vue$/
)

loadComp.keys().forEach((file) => {
  const { default: component } = loadComp(file)
  const name = file.match(/[A-Z][a-zA-Z]+/)[0]
  component.name = name
  components[name] = component
})

export default components
