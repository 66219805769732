var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-detail" }, [
    _c(
      "section",
      [
        _c("TopInfo", {
          attrs: { collection: true, entity: _vm.entity },
          on: { refresh: _vm.loadData }
        })
      ],
      1
    ),
    _c("section", [_c("Intro", { attrs: { entity: _vm.entity } })], 1),
    _c("section", [_c("Attachment", { attrs: { entity: _vm.entity } })], 1),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _vm.role === "org" && _vm.entity.status < 4
          ? _c(
              "mt-button",
              {
                attrs: {
                  disabled: !!_vm.entity.offerStatus,
                  loading: _vm.offering,
                  type: "primary",
                  shape: "round"
                },
                on: { click: _vm.onOffer }
              },
              [_vm._v(_vm._s(_vm.entity.offerStatus ? "已抢单" : "抢单") + " ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }