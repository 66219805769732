<template>
  <div class="project-detail">
    <section>
      <TopInfo @refresh="loadData" :collection="true" :entity="entity" />
    </section>
    <section>
      <Intro :entity="entity" />
    </section>
    <section>
      <Attachment :entity="entity" />
    </section>
    <div class="footer">
      <!-- 入驻机构有抢单按钮 -->
      <mt-button
        :disabled="!!entity.offerStatus"
        :loading="offering"
        @click="onOffer"
        v-if="role === 'org' && entity.status < 4"
        type="primary"
        shape="round"
        >{{ entity.offerStatus ? '已抢单' : '抢单' }}
      </mt-button>
    </div>
  </div>
</template>
<script>
import comps from './shared'
import { getDetail, getDetailForPublic, toOffer } from '@/api/enterprise'
import { Toast } from 'mint-ui'
import { mapGetters } from 'vuex'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  components: {
    ...comps
  },
  data() {
    const { projectId } = this.$route.params
    return {
      projectId,
      entity: {},
      offering: false
    }
  },
  computed: {
    ...mapGetters(['type', 'companyId']),
    role() {
      return this.type
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const token = getCookie(ACCESS_TOKEN)
      const __api = token ? getDetail : getDetailForPublic
      const { code, result } = await __api(this.projectId)
      if (code === 200) {
        this.entity = result
      }
    },
    // 抢单
    async onOffer() {
      try {
        this.offering = true
        const fd = new FormData()
        fd.append('projectId', this.projectId)
        fd.append('orgId', this.companyId)
        const { message } = await toOffer(fd)
        Toast(message)
        this.loadData()
        // this.$router.go(-1)
      } catch (error) {
        console.log(error)
        // if (error.response && error.response.data) {
        //   const { respMsg } = error.response.data
        //   if (respMsg) {
        //     if (respMsg === '信息费余额不足.') {
        //       MessageBox({
        //         title: '提示',
        //         message: '信息费不足，请充值',
        //         showCancelButton: true,
        //         confirmButtonText: '购买信息费',
        //         cancelButtonText: '取消'
        //       }).then((action) => {
        //         if (action === 'confirm') {
        //           this.$router.push('/admin/wallet-recharge')
        //         }
        //       })
        //     } else {
        //       Toast(respMsg)
        //     }
        //   }
        // }
      } finally {
        this.offering = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.project-detail {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-top: 10px;

  section {
    padding: 0 15px;
    margin-bottom: 10px;
  }

  .footer {
    display: flex;
    padding: 30px 0 40px 0;
    align-items: center;
    justify-content: center;

    .mint-button {
      width: 250px;
      font-size: 15px;
      border-radius: 41px;
    }
  }
}
</style>
