<template>
  <div class="attachment">
    <div class="attachment-head">附件</div>
    <div class="attachment-body">
      <div class="attachment-imgs">
        <a v-for="(item, index) in imglist" :key="index" :download="item.name" target="_blank" :href="item.url">
          <img :src="item.url" alt="" />
        </a>
      </div>
      <ul class="attachment-files">
        <li v-for="(item, index) in downloadlist" :key="index">
          <a target="_blank" :href="item.url">
            <span>{{ formatFileName(item.name) }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import defaultSettings from '@/config/defaultSettings'
export default {
  props: {
    entity: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    filelist() {
      const { attachments } = this.entity
      if (!attachments) return []
      return attachments.split(',').map(str => {
        return {
          type: str.split('.').pop(),
          name: str,
          url: str
        }
      })
    },
    imglist() {
      return this.filelist.filter(item => {
        return defaultSettings.imgTypeList.indexOf(item.type) > -1
      })
    },
    downloadlist() {
      return this.filelist.filter(item => {
        return defaultSettings.imgTypeList.indexOf(item.type) === -1
      })
    }
  },
  methods: {
    formatFileName(url) {
      if (typeof url !== 'string') {
        return url
      }
      const baseUrl = String(url)
        .trim()
        .split('?')[0]
      const arr = baseUrl.split('/')
      return arr[arr.length - 1]
    }
  }
}
</script>
<style lang="less" scoped>
.attachment {
  padding: 15px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 3px 0px rgba(23, 140, 249, 0.1);
  border-radius: 6px;

  &-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    padding-left: 13px;
    position: relative;
    line-height: 16px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 3px;
      height: 16px;
      background: rgba(23, 140, 249, 1);
    }
  }
  &-body {
    margin-top: 15px;
  }
  &-imgs {
    margin-top: -12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    a{
      width: 30%;
      margin-right: 3.33%;
      margin-bottom: 5%;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }
  &-files {
    margin: 0;
    margin-top: 12px;
    span {
      font-size: 14px;
      color: #178cf9;
    }
  }
}
</style>
