<template>
  <div class="intro">
    <div @click="onToggle" class="intro-head">
      <span>需求简介</span>
      <img v-if="showmore" :class="[isExpand ? 'expand': '']" src="@/assets/img/work/icon_arrow_down.svg" alt="">
    </div>
    <div :class="['intro-content', !isExpand && showmore && 'line2']" v-text="entity.content"></div>
  </div>
</template>
<script>
export default {
  props: {
    entity: {
      type: Object,
      default () {
        return {}
      }
    },
    showmore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isExpand: false
    }
  },
  methods: {
    onToggle () {
      if (!this.showmore) return
      this.isExpand = !this.isExpand
    }
  }
}
</script>
<style lang="less" scoped>
.intro{
  padding: 15px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 3px 0px rgba(23,140,249,0.1);
  border-radius:6px;

  &-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size:15px;
    padding-left: 13px;
    position: relative;
    line-height: 16px;
    justify-content: space-between;
    span{
      float: 1;
    }
    img{
      width: 10px;
      transform: rotate(-90deg);
      &.expand{
        transform: rotate(0);
      }
    }

    &::after{
      content: "";
      position: absolute;
      left: 0;
      width: 3px;
      height: 16px;
      background:rgba(23,140,249,1);
    }
  }
  &-content{
    font-size:14px;
    line-height: 1.4;
    margin-top: 10px;
    white-space: pre-wrap;
    &.line2{
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
