var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topinfo" }, [
    _c("div", { staticClass: "topinfo-head" }, [
      _c("span", { staticClass: "topinfo-no" }, [
        _vm._v("需求编号：" + _vm._s(_vm.entity.id))
      ]),
      _c(
        "span",
        {
          staticClass: "topinfo-status",
          style: { color: _vm.statusObj.color }
        },
        [_vm._v(_vm._s(_vm.statusObj.title))]
      )
    ]),
    _c("div", { staticClass: "topinfo-body" }, [
      _c("div", { staticClass: "topinfo-title" }, [
        _vm._v(" " + _vm._s(_vm.entity.title) + " ")
      ]),
      _c(
        "div",
        { staticClass: "topinfo-tags" },
        [
          _vm._l(_vm.typeList, function(item, index) {
            return _c("span", { key: index, staticClass: "topinfo-tag" }, [
              _vm._v(_vm._s(item))
            ])
          }),
          _c("span", { staticClass: "topinfo-tag" }, [
            _vm._v(_vm._s(_vm.timeDesc))
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }