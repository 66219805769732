var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "attachment" }, [
    _c("div", { staticClass: "attachment-head" }, [_vm._v("附件")]),
    _c("div", { staticClass: "attachment-body" }, [
      _c(
        "div",
        { staticClass: "attachment-imgs" },
        _vm._l(_vm.imglist, function(item, index) {
          return _c(
            "a",
            {
              key: index,
              attrs: { download: item.name, target: "_blank", href: item.url }
            },
            [_c("img", { attrs: { src: item.url, alt: "" } })]
          )
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "attachment-files" },
        _vm._l(_vm.downloadlist, function(item, index) {
          return _c("li", { key: index }, [
            _c("a", { attrs: { target: "_blank", href: item.url } }, [
              _c("span", [_vm._v(_vm._s(_vm.formatFileName(item.name)))])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }