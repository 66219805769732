<template>
  <div class="topinfo">
    <div class="topinfo-head">
      <span class="topinfo-no">需求编号：{{ entity.id }}</span>
      <span class="topinfo-status" :style="{ color: statusObj.color }">{{ statusObj.title }}</span>
    </div>
    <div class="topinfo-body">
      <div class="topinfo-title">
        {{ entity.title }}
      </div>
      <div class="topinfo-tags">
        <span class="topinfo-tag" v-for="(item, index) in typeList" :key="index">{{ item }}</span>
        <span class="topinfo-tag">{{ timeDesc }}</span>
      </div>
      <!--      <a v-if="collection" @click="onSwitchCollection" class="topinfo-collection" href="javascript:;">-->
      <!--        <img v-if="entity.inFavorite" src="~@/assets/img/project/icon_collection_yellow@2x.png" alt="">-->
      <!--        <img v-else src="~@/assets/img/project/icon_collection_nor@2x.png" alt="">-->
      <!--      </a>-->
    </div>
  </div>
</template>
<script>
import defaultSettings from '@/config/defaultSettings'
import { switchFavorite } from '@/api/public'
import { getProjectDateRange } from '@/utils/util'

export default {
  props: {
    entity: {
      type: Object,
      default() {
        return {}
      }
    },
    collection: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timeDesc() {
      const { createTime, endTime } = this.entity
      if (!createTime) return ''
      return getProjectDateRange(createTime, endTime)
    },
    typeList() {
      const { type_dictText: typeDictText } = this.entity
      if (!typeDictText) return []
      return typeDictText.split(',')
    },
    statusObj() {
      const { projectStatus } = this.entity
      return defaultSettings.statusEnum[projectStatus] || {}
    }
  },
  methods: {
    async onSwitchCollection() {
      const { id, inFavorite } = this.entity
      await switchFavorite({
        collectId: id,
        optType: inFavorite ? 'cancel' : 'save',
        type: 1
      })
      this.$emit('refresh')
    }
  }
}
</script>
<style lang="less" scoped>
.topinfo {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 3px 0px rgba(23, 140, 249, 0.1);
  border-radius: 12 rpx;
  padding: 0 15px;
  overflow: hidden;

  &-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #f2f2f2;
    justify-content: space-between;
  }

  &-no {
    color: #666666;
    font-size: 12px;
  }

  &-status {
    font-size: 12px;
  }

  &-body {
    padding: 15px 0;
    line-height: 1.2;
    position: relative;
  }

  &-title {
    word-break: break-all;
    font-weight: 600;
    font-size: 15px;
    margin-right: 18px;
  }

  &-tags {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-tag {
    height: 24px;
    font-size: 12px;
    color: #666666;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(245, 245, 245, 1);
    border-radius: 2px;
    margin-right: 5px;
    margin-top: 5px;
  }

  &-collection {
    position: absolute;
    right: 0;
    top: 15px;

    img {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
